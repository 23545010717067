// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery/dist/jquery.min")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Trix = require("trix")
require("@rails/actiontext")

const images = require.context('../my_images/', true)

import "my_javascripts/all"
import 'my_stylesheets/reset.css'
import 'my_stylesheets/common.scss'
import 'my_stylesheets/styles.css'